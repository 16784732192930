import { IResponsiveImage, EFallbackImageTypes } from '~/types/image'
import {
  IVehicle,
  NewVehicle,
  OrderVehicle,
  UsedVehicle
} from '~/types/vehicle'

// Available backgrounds from BBD
export enum EBackgroundImage {
  White = '0',
  Grey = '1', // (Legacy)
  Showroom = '8',
  CutOut = '9',
  Outdoor = '10',
  Museum = '11',
  LOFT = '12',
  HALL = '13', // (G-Class only)
  EQ = '14',
  LAKE = '15',
  BUSINESS_PARK = '40'
}

// Supported Image formats
enum IImageFormat {
  WEBP = 'W',
  JPG = 'A',
  PNG = 'P'
}

// Supported Image Sizes
enum IImageType {
  HD = '27' // 1920 x 1080
}

// Custom image dimentions
interface IDimensions {
  width: number
  height?: number | undefined
}

// Interface for generateImage params
interface IImage {
  url: string
  nightmode: boolean
  background: EBackgroundImage
  imgt?: string
  pov?: string | undefined
  dimensions?: IDimensions | undefined
  crop?: string
}

/**
 * Returns a class name for a new vehicle image container
 * @param  {string} vehicleClass
 * @param  {string} bodyStyle
 * @param  {boolean} isNightMode
 */
export const imageBackgroundClass = (
  vehicleClass: string,
  bodyStyle: string,
  isNightMode: boolean
) => {
  const useBackgroundFill =
    defaultBackground(null, vehicleClass, bodyStyle) === EBackgroundImage.CutOut
  return useBackgroundFill
    ? `os-car-image--systemBackground${isNightMode ? '-night' : ''}`
    : null
}
/**
 * Return system prefered background for new car images
 * @param  {string|undefined} vehicleClass
 */
export const defaultBackground = (
  url: string | null,
  vehicleClass?: string,
  bodyStyle?: string,
  useBackgroundDefaults: boolean = false
): EBackgroundImage => {
  // TODO - Remove this.

  if (vehicleClass?.toUpperCase().startsWith('EQV')) {
    return EBackgroundImage.BUSINESS_PARK
  }
  if (vehicleClass?.toUpperCase().startsWith('EQ')) {
    if (
      (vehicleClass?.toUpperCase() === 'EQE' && bodyStyle === 'SUV') ||
      ['EQA', 'EQB', 'EQE'].indexOf(vehicleClass?.toUpperCase()) > 0
    ) {
      return EBackgroundImage.CutOut
    }
    return EBackgroundImage.EQ
  }

  // We use useBackgroundDefaults on Gallery, interior & exterior
  if (!useBackgroundDefaults) {
    if (vehicleClass) {
      return EBackgroundImage.CutOut
    }
  }
  switch (vehicleClass) {
    case 'A-CLASS':
    case 'B-CLASS':
    case 'GLA-CLASS':
    case 'GLB-CLASS':
    case 'GLE-CLASS':
    case 'CLA-CLASS':
      return EBackgroundImage.CutOut
    case 'S-CLASS':
    case 'E-CLASS':
    case 'GLC-CLASS':
      return EBackgroundImage.LOFT
    case 'GT-CLASS':
    case 'SL-CLASS':
      return EBackgroundImage.LAKE
    case 'smart':
      return EBackgroundImage.Showroom
    default:
      if (url) {
        const newURL = new URL(url)
        return (
          (newURL.searchParams.get('BKGND') as EBackgroundImage) ||
          EBackgroundImage.LOFT
        )
      }
      return EBackgroundImage.LOFT
  }
}

/**
 * Return array of image urls for Iamge-Sequence from a starUrl
 * @param url
 * @param nightmode
 * @param background = EBackgroundImage
 * @returns string[] image urls
 */
export const getImageSequenceUrls = (
  url,
  nightmode = false,
  background = EBackgroundImage.White
): string[] => {
  const bg =
    background === EBackgroundImage.CutOut
      ? EBackgroundImage.Showroom
      : background

  return Array.from(Array(36), (d, i) => {
    const pov = i === 0 ? '000' : i < 10 ? '0' + i * 10 : i * 10
    return generateImage({
      url,
      nightmode,
      background: bg,
      pov: `BE${pov}`,
      dimensions: {
        width: 1920
      }
    })
  })
}

/**
 * Return a combined set of image urls for interior & exterior Gallery
 * @param  {} {url
 * @param  {} nightmode=false
 * @param  {} background=EBackgroundImage.White
 * @param  {} width=1000}
 */
export const getGalleryUrls = ({
  url = '',
  nightmode = false,
  background = EBackgroundImage.White,
  width = 1000,
  povs = ['BE040', 'BE090', 'BE190', 'BI1', 'BI2', 'BI3', 'BI4']
}): string[] => {
  return povs.map((pov) => {
    return generateImage({
      url,
      nightmode,
      background,
      pov,
      dimensions: {
        width
      }
    })
  })
}

/**
 * Return a new car image url with customised options
 * @param  {} {url
 * @param  {} nightmode
 * @param  {} background=EBackgroundImage.LOFT
 * @param  {} imgt='A27'
 * @param  {} pov='BE040' - default
 * @param  {} dimensions=undefined
 * @param  {} crop=undefined}
 * @returns string
 */
export const generateImage = ({
  url,
  nightmode,
  background = EBackgroundImage.LOFT,
  imgt = 'A27',
  pov = 'BE040',
  dimensions = undefined,
  crop = undefined
}: IImage): string => {
  // If there is a placeholder image, don't modify URL
  if (url.includes('no_image')) {
    return url
  }

  const baseURI: string = url.split('&&')[0]
  const newURL = new URL(baseURI)
  newURL.searchParams.set('BKGND', background)
  newURL.searchParams.set(
    'POV',
    [pov, 'PZM', `SZ${nightmode ? 'N' : 'D'}`].join(',')
  )

  if (crop) {
    newURL.searchParams.set('crop', crop)
  } else {
    newURL.searchParams.delete('crop')
  }

  if (background === EBackgroundImage.CutOut) {
    newURL.searchParams.set('uni', 'c') // applicable to CutOut only
    newURL.searchParams.set('IMGT', 'W27')
  } else {
    newURL.searchParams.set('IMGT', imgt)
  }

  dimensions?.width &&
    newURL.searchParams.set('width', String(dimensions.width))
  dimensions?.height &&
    newURL.searchParams.set('height', String(dimensions.height))
  newURL.searchParams.set('fb', '1')
  return newURL.href.replace(/%2C/g, ',')
}

export interface IVehicleImageOptions {
  url: string
  vehicle?: IVehicle | OrderVehicle | NewVehicle | UsedVehicle
  vehicleClass?: string
  vehicleBodyStyle?: string
  isNightMode?: boolean
  isCropped?: boolean
  width?: number
  height?: number
  background?: EBackgroundImage
  isCutout?: boolean
  pov?: string
  imgt?: string
  isResponsive?: boolean
  isNew?: boolean
  srcsetSizes?: number[]
  sizes?: string
  fallback?: EFallbackImageTypes
}

/**
 * Handler to format BBD image url with width param
 * @returns string
 *  ['1', 'BIS2', 'BIS3', 'BIS6', 'BIS1', 'BIS4'].
 */
export const getInteriorImages = (opts?: IVehicleImageOptions) => {
  return ['BIS4', 'BIS2', 'BIS5', 'BIS6', 'BIS1', 'BIS3'].map((pov) => {
    const { url } = getVehicleImage(opts)
    const defaultBG = defaultBackground(
      opts?.url || null,
      opts?.vehicleClass,
      opts?.vehicleBodyStyle,
      true
    )
    return generateImage({
      url,
      nightmode: opts?.isNightMode || false,
      background:
        defaultBG === EBackgroundImage.CutOut
          ? EBackgroundImage.Showroom
          : defaultBG,
      pov,
      dimensions: {
        width: 1024
      },
      crop: undefined
    })
  })
}
/**
 * Returns a single interior shot looking at dash
 * @param  {} {vehicle
 * @param  {} nightmode=true
 * @param  {} width=1000}
 */

export const getInteriorImage = ({
  url = '',
  vehicleClass = '',
  nightmode = true,
  width = 1000
}) => {
  const defaultBG = defaultBackground(url, vehicleClass)
  return generateImage({
    url,
    nightmode,
    background:
      defaultBG === EBackgroundImage.CutOut
        ? EBackgroundImage.Showroom
        : defaultBG,
    pov: 'BI1',
    dimensions: {
      width
    }
  })
}

/**
 *  Place to override the default crop
 */
const getDefaultCrop = (): string => {
  /* switch (vehicleClass) {
    case 'EQS':
      if (['Saloon', 'SUV'].includes(vehicleBodyStyle)) return '0,0,1920,780'
      break
    case 'C-CLASS':
      if (['Estate', 'Saloon'].includes(vehicleBodyStyle)) return '0,0,1920,780'
      break
    case 'smart':
      return '0,140,1920,940'
  } */

  return '60,0,1860,1080'
}

/**
 * Returns a new car image UrL
 * @param opts IVehicleImageOptions
 * @returns
 */
export const getVehicleImage = (
  opts?: IVehicleImageOptions
): IResponsiveImage => {
  const vehicleClass = opts?.vehicle?.VehicleClass || opts?.vehicleClass
  const bodyStyle = opts?.vehicle?.BodyStyle || opts?.vehicleBodyStyle
  const isSmart = vehicleClass?.toLocaleLowerCase() === 'smart'

  const alt = `Image of ${vehicleClass} ${bodyStyle}`
  const imageIsPlaceHolder = opts?.url.includes('no_image')

  if (opts?.isCutout && imageIsPlaceHolder) {
    return { alt, url: '/images/vehicle/defaults/missing-model-nobg.webp' }
  }

  // For Used Cars we can just return the image (optionally resized)
  if (!opts?.url.includes('starconnect')) {
    // TODO: Return responsive option for used
    return {
      alt,
      url: opts?.width
        ? opts?.url.replace('640/480', `${opts.width}/${opts.width * 0.75}`)
        : opts?.url,
      ...(opts?.width && { width: opts?.width }),
      ...(opts?.height && { height: opts?.height }),
      imageIsPlaceHolder,
      isNew: false,
      fallback: isSmart
        ? EFallbackImageTypes.UsedSmart
        : EFallbackImageTypes.UsedCar
    }
  }

  // New car BBD image

  // Unless cutout or background specified default to OS system defaults @see defaultBackground
  const background = opts?.isCutout
    ? EBackgroundImage.CutOut
    : opts?.background
      ? opts.background
      : defaultBackground(opts?.url, vehicleClass, bodyStyle)

  const imageFormat =
    background === EBackgroundImage.CutOut
      ? IImageFormat.WEBP
      : IImageFormat.JPG

  const url = generateImage({
    url: opts?.url.replace('ce.i.daimler.com', 'ce.i.mercedes-benz.com'),
    nightmode: !!opts?.isNightMode,
    background,
    imgt: `${imageFormat}${IImageType.HD}`,
    pov: opts?.pov || 'BE040',
    ...(opts?.isCropped && {
      crop: getDefaultCrop(vehicleClass!, bodyStyle!) // Add custom crop
    }),
    ...(opts?.width && { dimensions: { width: opts.width } })
  })

  const backgroundClass =
    defaultBackground(opts?.url, vehicleClass, bodyStyle) ===
      EBackgroundImage.CutOut && !opts?.isCutout
      ? opts?.isNightMode
        ? 'os-car-image--systemBackground-night'
        : 'os-car-image--systemBackground'
      : null

  const image = {
    url,
    ...(backgroundClass && { backgroundClass }),
    ...(imageIsPlaceHolder && { imageIsPlaceHolder: true }),
    // ...(srcset && { srcset }),
    ...(opts?.sizes && { sizes: opts?.sizes }),
    ...(opts?.sizes && { sizes: opts?.sizes }),
    ...(opts?.width && { width: opts?.width }),
    ...(opts?.height && { height: opts?.height }),
    fallback: isSmart
      ? EFallbackImageTypes.NewSmart
      : EFallbackImageTypes.NewCar,
    isNew: true,
    alt
  }

  return image
}
